<template>
  <div class="pa-10">
    <v-row>
      <v-col cols="12">
        <v-text-field
          v-model="searchQuery"
          width="100%"
          height="70px"
          dark
          append-icon="mdi-magnify"
          solo
        >
          <template v-slot:label>
            <span style="font-size: 20px;">Search Channels</span>
          </template>
        </v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col v-for="channel in filteredChannels" :key="channel.name" cols="6" md="3">
        <v-hover v-slot:default="{ hover }">
          <v-card
            style="background-color: transparent; cursor: pointer;"
            @click="openDialog(channel)"
            class="d-flex flex-column overlay-container fade-in-left"
            :class="{ 'hover-scale': hover }"
          >
            <v-img :src="channel.logo" class="test" style="background-size: contain;"></v-img>
            <template v-if="hover">
              <div class="overlay-content">
                <v-icon large color="white">mdi-play-circle-outline</v-icon>
                <div class="text-h5 mt-2">{{ channel.name }}</div>
              </div>
            </template>
          </v-card>
        </v-hover>
      </v-col>
    </v-row>
    <v-dialog v-model="dialog" max-width="800px">
      <v-card style="background-color: black;">
        <iframe
          v-if="selectedChannel"
          :src="selectedChannel.link"
          width="100%"
          height="360"
          frameborder="0"
          allow="encrypted-media"
          allowfullscreen
        ></iframe>
      </v-card>
    </v-dialog>
    <h3>Hi world</h3>
    <!-- Dialog for Ad Blocker Instructions -->
    <v-dialog v-model="adBlockerDialog" max-width="600px" style="overflow-x: hidden; overflow-y: auto;">
      <v-card dark style="border-radius:20px;background-color:black; text-align:center;overflow-x: hidden; overflow-y: auto;">
        <v-card-title class="justify-center">
          <v-img
            src="../assets/logo1.png"
            alt="Logo"
            height="200px"
            contain
          ></v-img>
        </v-card-title>
        <v-row justify="center">
          <v-card-title class="text-h5">Install uBlock Origin</v-card-title>
        </v-row>
        <v-card-text>
          <p>
            It seems like you don't have an ad blocker installed. We recommend using uBlock Origin for a better browsing experience. Follow the steps below to install it on your browser:
          </p>
          <h3>For Chrome</h3>
          <ol class="text-left" style="display: inline-block;">
            <li>Open the <a href="https://chrome.google.com/webstore/detail/ublock-origin/cjpalhdlnbpafiamejdnhcphjbkeiagm" target="_blank">Chrome Web Store</a>.</li>
            <li>Search for "uBlock Origin".</li>
            <li>Click "Add to Chrome".</li>
            <li>Confirm by clicking "Add extension".</li>
          </ol>
          <h3>For Firefox</h3>
          <ol class="text-left" style="display: inline-block;">
            <li>Open the <a href="https://addons.mozilla.org/en-US/firefox/addon/ublock-origin/" target="_blank">Firefox Add-ons</a>.</li>
            <li>Search for "uBlock Origin".</li>
            <li>Click "Add to Firefox".</li>
            <li>Confirm by clicking "Add".</li>
          </ol>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      adBlockerDialog: false,
      selectedChannel: null,
      searchQuery: "",
      channels: [
        {
          name: "Cartoon Network Asia",
          logo: "https://picon.ngfiles.com/864000/flash_864030_card.png?f1668777998",
          link: "https://dlhd.so/embed/stream-339.php",
        },
        {
          name: "WVTMDT1",
          logo: "https://play-lh.googleusercontent.com/y8pzzzyZIrS-9OPxcS8joxihyXBTPa8FfoMqp9l2YnoQbw7Pu_CM5A9pspJesw5fh2I",
          link: "https://dlhd.so/embed/stream-53.php",
        },
        {
          name: "Nick Pluto TV 1",
          logo: "https://deadline.com/wp-content/uploads/2023/02/nickelodeon.jpg?w=681&h=383&crop=1",
          link: "https://dlhd.so/embed/stream-650.php",
        },
        {
          name: "ESPN USA",
          logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTX1HDlf2PW8gd-Wke8Tho1KpX6KB8Rk5AxTA&s",
          link: "https://dlhd.sx/embed/stream-44.php",
        },
        {
          name: "ESPN 2 USA",
          logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTX1HDlf2PW8gd-Wke8Tho1KpX6KB8Rk5AxTA&s",
          link: "https://dlhd.sx/embed/stream-45.php",
        },
        {
          name: "Disney Channel",
          logo:"https://wallpapers.com/images/high/disney-channel-white-and-blue-logo-z6539nf1izhih669.webp",
          link: "https://dlhd.so/embed/stream-312.php",
        },
        {
          name: "Disney XD",
          logo:"https://wallpapers.com/images/high/disney-xd-modern-logo-a0vag6fghoyxh5s8.webp",
          link: "https://dlhd.so/embed/stream-314.php",
        },
        {
          name: "NBA TV",
          logo: "https://cdn.phenompeople.com/CareerConnectResources/NBANBAUS/social/1024x512-1670500646586.jpg",
          link: "https://dlhd.so/embed/stream-404.php",
        },
        {
          name: "Benfica Tv",
          logo: "https://th.bing.com/th/id/OIP.0Hkh-Z_F4LRpvKmFVABwfQAAAA?rs=1&pid=ImgDetMain",
          link: "https://dlhd.so/embed/stream-380.php",
        },
        {
          name: "Priemere League",
          logo: "https://www.fanzword.com/wp-content/uploads/2024/04/p3605402_b_h8_ar.jpg",
          link: "https://dlhd.so/embed/stream-414.php",
        },
        {
          name: "SuperSport Rugby",
          logo: "https://www.logolynx.com/images/logolynx/84/842f84f5c1ad785d4e29738674ad3944.jpeg",
          link: "https://dlhd.so/embed/stream-421.php",
        },
        {
          name: "SuperSport Cricket",
          logo: "https://zeerakmedia.com/wp-content/uploads/2024/03/WWW.ZEERAKMEDIA.COM_.jpg",
          link: "https://dlhd.so/embed/stream-368.php",
        },
        {
          name: "Comedy Central",
          logo: "https://m.media-amazon.com/images/M/MV5BNWM4YjM0NzktYmM0Ni00MzVlLTlkYWYtOGFjOTQ2Y2RjYTJmXkEyXkFqcGdeQXVyNjc5MzY2Mjk@._V1_.jpg",
          link: "https://dlhd.so/embed/stream-310.php",
        },
        {
          name: "Boomerang",
          logo: "https://eeofe.org/media/enews_032015_boomerang.jpg",
          link: "https://dlhd.so/embed/stream-648.php",
        },
        {
          name: "Cinemax",
          logo: "https://deadline.com/wp-content/uploads/2016/12/cinemax-logo-e1523031626161.jpg?w=418",
          link: "https://dlhd.so/embed/stream-374.php",
        },
        {
          name: "SKY Sports Soccer",
          logo: "https://th.bing.com/th/id/R.4b2bab897039548e516c60e1663de63d?rik=900vlzA%2b4iqbFw&pid=ImgRaw&r=0",
          link: "https://dlhd.so/embed/stream-130.php",
        },
        {
          name: "Mzanzi Magic",
          logo: "https://cdn-mzansimagic.dstv.com/images/News/2021/12/07/529766/5/1638871891-47_Mzansi_Magic_Logo.jpg",
          link: "https://dlhd.so/embed/stream-786.php",
        },
        {
          name: "BET",
          logo: "https://ts2.mm.bing.net/th?q=BET%20Uluslararas%C4%B1%20BET%20International",
          link: "https://dlhd.so/embed/stream-306.php",
        },
        {
          name: "TLC",
          logo: "https://upload.wikimedia.org/wikipedia/commons/3/3c/TLC_logo_%282023%29.svg",
          link: "https://dlhd.so/embed/stream-337.php",
        },
        {
          name: "Animal Planet",
          logo: "https://th.bing.com/th/id/OIP.61FJuWeH8nturRkpIXdE8QHaEK?rs=1&pid=ImgDetMain",
          link: "https://dlhd.so/embed/stream-304.php",
        },
        {
          name: "Discovery Channel",
          logo: "https://www.tvbeurope.com/wp-content/uploads/2020/09/discovery-logo.jpg",
          link: "https://dlhd.so/embed/stream-313.php",
        },
        {
          name: "The Food Network",
          logo: "https://thecentraltrend.com/wp-content/uploads/2017/01/food-network-veg.jpg",
          link: "https://dlhd.so/embed/stream-384.php",
        },
        {
          name: "Super Sport Action",
          logo: "https://mir-s3-cdn-cf.behance.net/project_modules/1400/2f4262105982345.5f86c8ac0dfc4.jpg",
          link: "https://dlhd.so/embed/stream-420.php",
        },
        {
          name: "Super Sport Variety 1",
          logo: "https://mir-s3-cdn-cf.behance.net/project_modules/1400/2f4262105982345.5f86c8ac0dfc4.jpg",
          link: "https://dlhd.so/embed/stream-416.php",
        },
        {
          name: "Super Sport Variety 2",
          logo: "https://mir-s3-cdn-cf.behance.net/project_modules/1400/2f4262105982345.5f86c8ac0dfc4.jpg",
          link: "https://dlhd.so/embed/stream-417.php",
        },  
        {
          name: "Super Sport Variety 3",
          logo: "https://mir-s3-cdn-cf.behance.net/project_modules/1400/2f4262105982345.5f86c8ac0dfc4.jpg",
          link: "https://dlhd.so/embed/stream-418.php",
        },  

        {
          name: "Super Sport Variety 4",
          logo: "https://mir-s3-cdn-cf.behance.net/project_modules/1400/2f4262105982345.5f86c8ac0dfc4.jpg",
          link: "https://dlhd.so/embed/stream-419.php",
        }, 
        {
          name: "Super Sport Grandstand",
          logo: "https://images.supersport.com/media/bukfpzcy/grandstand_logo_may20_1050.jpg",
          link: "https://dlhd.so/embed/stream-412.php",
        }, 
        {
          name: "Super Sport MotorSport",
          logo: "https://i.servimg.com/u/f60/11/00/26/24/m-logo11.jpg",
          link: "https://dlhd.so/embed/stream-424.php",
        },
      
      ],
    };
  },
  computed: {
    filteredChannels() {
      return this.channels.filter(channel =>
        channel.name.toLowerCase().includes(this.searchQuery.toLowerCase())
      );
    },
  },
  methods: {
    openDialog(channel) {
      localStorage.setItem('adBlockerDialogShown', 'false');
      this.selectedChannel = channel;
      this.dialog = true;
    },
    checkAdBlocker() {
      if (!localStorage.getItem('adBlockerDialogShown')) {
        const adUrl = "https://example.com/ad.js";
        const testAd = document.createElement("img");
        testAd.src = adUrl;
        testAd.style.display = "none";
        testAd.onerror = () => {
          this.adBlockerDialog = true;
        };
        document.body.appendChild(testAd);
      }
      localStorage.setItem('adBlockerDialogShown', 'true');
    },
    onAdBlockerDialogClose() {
      this.adBlockerDialog = false;
    }
  },
  watch: {
    dialog(newVal) {
      console.log("Dialog visibility changed:", newVal);
    },
    selectedChannel(newVal) {
      console.log("Selected channel changed:", newVal ? newVal.name : "None");
    },
  },
  mounted() {
    this.checkAdBlocker();
  },
};
</script>

<style scoped>
.d-flex {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.overlay-container {
  position: relative;
  transition: transform 0.3s ease-in-out;
}
.overlay-container.hover-scale {
  transform: scale(1.06);
}
.test{
  height:240px
}
@media only screen and (max-width: 600px) {
.test{
height:100px
}
}
.overlay-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(10px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}
.text-h5, .text-subtitle-1, .text-caption {
  color: white;
  margin-top: 8px;
}

/* Added fade-in-left animation */
.fade-in-left {
  -webkit-animation: fade-in-left 0.6s cubic-bezier(0.39, 0.575, 0.565, 1.000) both;
  animation: fade-in-left 0.6s cubic-bezier(0.39, 0.575, 0.565, 1.000) both;
}

@-webkit-keyframes fade-in-left {
  0% {
    -webkit-transform: translateX(-50px);
    transform: translateX(-50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes fade-in-left {
  0% {
    -webkit-transform: translateX(-50px);
    transform: translateX(-50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
</style>
