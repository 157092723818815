<template>
  <div style="overflow-x:hidden">
    <v-row justify="center">
      <v-alert
        v-model="showSuccessAlert"
        type="success"
        style="position: absolute; z-index: 99; margin-top: 20px;"
        dismissible
        width="500px"
        transition="scale-transition"
      >
        {{ successMessage }}
      </v-alert>
      <v-alert
        v-model="showErrorAlert"
        type="error"
        style="position: absolute; z-index: 99; margin-top: 20px;"
        dismissible
        width="500px"
        transition="scale-transition"
      >
        {{ errorMessage }}
      </v-alert>
    </v-row>
    <div class="movie-banner" :style="{ backgroundImage: 'url(' + movieBackdropUrl + ')' }">
      <v-col cols="12">
        <div class="overlay">
          <div class="movie-info mx-7">
            <div class="movie-title animate-title">{{ movieTitle }}</div>
            <div class="mb-3">
              {{ movieRating }}
              <span class="reviews-count mb-3">{{ reviewsCount }} Reviews</span>
            </div>
            <p class="movie-description animate-description">{{ movieDescription }}</p>
            <v-btn height="46px" color="#23ffe5" @click="playContent">Play</v-btn>
            <v-btn height="46px" class="mx-3" outlined dark @click="fetchTrailer">Trailer</v-btn>
            <v-btn v-if="!isTvShow" class="mx-3" height="46px" outlined dark @click="fetchMagnetLink">
              <v-icon>mdi-download</v-icon> Download
            </v-btn>
            <v-btn v-if="isTvShow" class="mx-3" height="46px" outlined dark @click="openDownloadDialog">
              <v-icon>mdi-download</v-icon> Download
            </v-btn>
            <v-btn text v-if="isAuthenticated" height="46px" dark @click="addToWatchlist">
              <lord-icon
    src="https://cdn.lordicon.com/zrkkrrpl.json"
    trigger="hover"
    state="hover-swirl"
    colors="primary:#ffffff,secondary:#23ffe5"
    style="width:50px;height:50px">
</lord-icon>
            </v-btn>
        
 
          </div>
        </div>
      </v-col>
    </div>

    <v-row class="d-none d-md-block">
      <Details />
    </v-row>

    <v-tabs v-if="isTvShow" v-model="selectedTab" background-color="black" dark centered>
      <v-tab>Seasons</v-tab>
      <v-tab>Cast</v-tab>
    </v-tabs>

    <v-tabs-items style="background-color: black" v-model="selectedTab" v-if="isTvShow">
      <v-tab-item>
        <v-row v-if="isTvShow" justify="center" class="my-7">
          <v-col cols="3"></v-col>
          <v-col class="episode-dropdown">
            <v-select
              outlined
              dark
              class="mx-2"
              v-model="selectedSeason"
              :items="seasons"
              label="Season"
              @change="fetchEpisodes"
              dense
            ></v-select>
          </v-col>
          <v-col cols="3"></v-col>
        </v-row>
        <v-row v-if="isTvShow" justify="center">
          <v-list class="pa-7" dark style="background-color:black; overflow-x:hidden; height:550px;width:92%;">
            <v-row>
              <v-col
                cols="12" sm="12" md="6"
                v-for="episode in episodes"
                :key="episode.id"
                height="70px"
                @click="selectEpisode(episode), playContent()"
   
                class="episode-item"
              >
                <v-list-item-avatar height="100px" width="100px">
                  <v-img :src="`https://image.tmdb.org/t/p/w300${episode.still_path}`"></v-img>
                  <div class="play-button-overlay">
                    <v-icon>mdi-play-circle</v-icon>
                  </div>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>{{ episode.episode_number }}. {{ episode.name }}</v-list-item-title>
                  <v-list-item-subtitle>{{ episode.overview }}</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <span>{{ episode.runtime }}m</span>
                </v-list-item-action>
              </v-col>
            </v-row>
          </v-list>
        </v-row>
      </v-tab-item>
      <v-tab-item>
        <Cast />
      </v-tab-item>
    </v-tabs-items>

    <div v-if="!isTvShow">
      <Cast />
    </div>

    <div>
      <LikeThis />
    </div>

    <!-- Play Content Dialog -->
    <v-dialog v-model="showVideoDialog"  width="800">
      <v-card color="black " style="overflow-x: hidden;" class="dialog-content">
        <v-row justify="end" class="pa-3">
        <v-btn color="black" style="position: absolute" dark @click="showVideoDialog = false">
          <v-icon style="font-size: 30px">mdi-close</v-icon>
        </v-btn>
      </v-row>
        <div class="scroll-container" style="overflow-y: hidden;overflow-x: hidden;">
          <iframe v-if="embedUrl" :src="embedUrl" width="100%" height="450" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>
          <!-- <v-col v-if="!isTvShow">
            <v-btn width="100%" class="mx-3" outlined dense dark @click="changePlayerMovie">Change Player</v-btn>
          </v-col> -->
          <!-- Episode List Section -->
          <v-row v-if="isTvShow">
            <!-- <v-col>
              <v-btn width="100%" outlined dense dark @click="changePlayer">Change Player</v-btn>
            </v-col> -->
            <v-col>
              <v-btn v-if="isTvShow" color="#23ffe5" width="100%" @click="playNextEpisode">Next Episode</v-btn>
            </v-col>
          </v-row>
        </div>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showDialog" style="overflow-x: hidden;" width="800">
      <v-card color="black">
        <v-row justify="end" class="pa-3">
        <v-btn color="black" style="position: absolute" dark @click="showDialog = false">
          <v-icon style="font-size: 30px">mdi-close</v-icon>
        </v-btn>
</v-row>
        <iframe v-if="trailerKey" width="100%" height="450" :src="'https://www.youtube.com/embed/' + trailerKey" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>
      </v-card>
    </v-dialog>

    <!-- Download Season Dialog -->
    <v-dialog v-model="showDownloadDialog" width="400" height="800px">
      <v-card class="pa-12" style="overflow-x:hidden;overflow-y: hidden;" color="black">
        <v-card-title style="color:white">Select Season to Download</v-card-title>
        <v-card-text>
          <v-select
            v-model="selectedSeasonToDownload"
            :items="seasons"
            filled dark
            label="Season"
            outlined dense
          ></v-select>
        </v-card-text>

        <v-row justify="center">
          <v-btn color="#23ffe5" @click="downloadSeason(selectedSeasonToDownload)">Download</v-btn>
        </v-row>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  getFirestore,
  collection,
  addDoc,
  query,
  where,
  getDocs,
  updateDoc,
  doc,
} from "firebase/firestore";
import { firebaseApp } from "../firebaseConfig";
import axios from "axios";

import Details from "../components/Details.vue";
import Cast from "../components/Cast.vue";
import LikeThis from "../components/LikeThis.vue";

const db = getFirestore(firebaseApp);

export default {
  data() {
    return {
      movieTitle: "",
      movieRating: "",
      movieBackdropUrl: "",
      moviePosterUrl: "",
      initialFetchDone: false,
      isTvShow: false,
      movieRuntime: "",
      movieDescription: "",
      reviewsCount: "",
      seasons: [],
      selectedSeason: 1,
      selectedEpisode: 1,
      episodes: [],
      showDialog: false,
      trailerKey: null,
      showVideoDialog: false,
      embedUrl: "",
      showSuccessAlert: false,
      successMessage: "",
      showErrorAlert: false,
      errorMessage: "",
      selectedTab: 0,
      magnetLink: "",
      showDownloadDialog: false,
      selectedSeasonToDownload: null,
      torrents: [],
      torrentsLoaded: false,
    };
  },
  components: {
    Details,
    Cast,
    LikeThis,
  },
  mounted() {
    const imdbId = localStorage.getItem("imdbId");
    const authId = localStorage.getItem("authId");
    if (imdbId) {
      this.fetchMovieDetails(imdbId);
      this.fetchSeasonsAndEpisodes(imdbId).then(() => {
        if (this.episodes.length === 0 && !this.initialFetchDone) {
          this.initialFetchDone = true;
          this.fetchEpisodes();
        }
      });
      this.checkIfTvShow(imdbId);
      if (authId) {
        this.fetchContinueWatching(authId, imdbId);
      }
    } else {
      console.error("No IMDb ID found in localStorage");
    }

    this.checkIfTvShow(imdbId).then(() => {
      if (this.isTvShow === true) {
        const checkValue = localStorage.getItem("log");
        if (checkValue === "1") {
          localStorage.setItem("log", "2");
          window.location.reload();
        }
      }
    });
  },
  computed: {
    episodeOptions() {
      return this.episodes.map(episode => episode.episode_number);
    },
    isAuthenticated() {
      return localStorage.getItem("authId") !== null;
    },
  },
  methods: {
    async downloadAllSeasons() {
      const imdbId = localStorage.getItem("imdbId");
      if (!imdbId) {
        console.error("IMDb ID not available");
        return;
      }
      const imdbIdWithoutTT = imdbId.replace("tt", "");
      let allMagnetLinks = [];

      try {
        const totalPages = 30;
        for (let page = 1; page <= totalPages; page++) {
          const response = await axios.get(
            `https://eztvx.to/api/get-torrents?imdb_id=${imdbIdWithoutTT}&page=${page}`
          );
          if (response.data && response.data.torrents) {
            const filteredTorrents = response.data.torrents
              .filter((torrent) => {
                const seasonEpisodeRegex = /S\d{2}E\d{2}/;
                return seasonEpisodeRegex.test(torrent.filename);
              })
              .map((torrent) => ({
                filename: torrent.filename,
                magnetLink: torrent.magnet_url,
              }));

            allMagnetLinks = allMagnetLinks.concat(filteredTorrents.map(torrent => torrent.magnetLink));
          }
        }
        
        if (allMagnetLinks.length > 0) {
          allMagnetLinks.forEach(link => {
            window.open(link, "_blank");
          });
        } else {
          this.errorMessage = "No torrents found for the TV show.";
          this.showErrorAlert = true;
        }
      } catch (error) {
        console.error("Error fetching torrents:", error);
      }
    },

    async downloadSeason(season) {
      const imdbId = localStorage.getItem("imdbId");
      if (!imdbId) {
        console.error("IMDb ID not available");
        return;
      }
      const imdbIdWithoutTT = imdbId.replace("tt", "");
      let seasonMagnetLinks = [];

      try {
        const response = await axios.get(
          `https://eztvx.to/api/get-torrents?imdb_id=${imdbIdWithoutTT}`
        );
        if (response.data && response.data.torrents) {
          const filteredTorrents = response.data.torrents
            .filter((torrent) => {
              const seasonRegex = new RegExp(`S${String(season).padStart(2, '0')}`);
              return seasonRegex.test(torrent.filename);
            })
            .map((torrent) => ({
              filename: torrent.filename,
              magnetLink: torrent.magnet_url,
            }));

          seasonMagnetLinks = filteredTorrents.map(torrent => torrent.magnetLink);
        }

        if (seasonMagnetLinks.length > 0) {
          seasonMagnetLinks.forEach(link => {
            window.open(link, "_blank");
          });
        } else {
          this.errorMessage = `No torrents found for Season ${season}.`;
          this.showErrorAlert = true;
        }
      } catch (error) {
        console.error("Error fetching torrents:", error);
      }
    },

    async fetchPirateBayTorrents() {
      const imdbId = localStorage.getItem("imdbId");
      if (!imdbId) {
        console.error("IMDb ID not available");
        return;
      }
      const imdbIdWithoutTT = imdbId.replace("tt", "");
      try {
        const response = await axios.get(
          `https://apibay.org/q.php?q=${imdbIdWithoutTT}&cat=205`
        );
        const torrents = response.data;

        if (torrents.length > 0) {
          torrents.forEach(torrent => {
            window.open(`magnet:?xt=urn:btih:${torrent.info_hash}`, "_blank");
          });
        } else {
          this.errorMessage = "No torrents found on The Pirate Bay.";
          this.showErrorAlert = true;
        }
      } catch (error) {
        console.error("Error fetching torrents from The Pirate Bay:", error);
      }
    },

    async fetchTorrents() {
      await this.downloadAllSeasons();
      await this.fetchPirateBayTorrents();
    },

    scrollToTop() {
      window.scrollTo(0, 0);
    },
    async initializeAdBlocker() {
      try {
        const blocker = await WebExtensionBlocker.fromPrebuiltAdsAndTracking({
          path: 'https://easylist.to/easylist/easylist.txt'
        });
        blocker.enableBlockingInBrowser(window);
        this.applyAdBlocker(blocker);
      } catch (error) {
        console.error('Failed to initialize ad blocker:', error);
      }
    },
    applyAdBlocker(blocker) {
      const iframes = document.querySelectorAll('iframe');
      iframes.forEach((iframe) => {
        const src = iframe.src;
        if (blocker.match(src)) {
          iframe.remove();
        }
      });
    },
    async checkIfTvShow(imdbId) {
      try {
        const response = await axios.get(
          `https://api.themoviedb.org/3/find/${imdbId}?api_key=a6a07bdb1ae12672ae2d301063d83c40&language=en-US&external_source=imdb_id`
        );
        const isTvShow = response.data.tv_results.length > 0;
        this.isTvShow = isTvShow;
      } catch (error) {
        console.error("Failed to check if IMDb ID is for a TV show:", error);
      }
    },
    async fetchMovieDetails(imdbId) {
      try {
        const response = await axios.get(
          `https://api.themoviedb.org/3/find/${imdbId}?api_key=a6a07bdb1ae12672ae2d301063d83c40&language=en-US&external_source=imdb_id`
        );
        let media =
          response.data.movie_results[0] || response.data.tv_results[0];
        if (media) {
          this.movieTitle = media.title || media.name;
          this.movieRating = media.vote_average;
          this.movieBackdropUrl = `https://image.tmdb.org/t/p/original${media.backdrop_path}`;
          this.moviePosterUrl = `https://image.tmdb.org/t/p/original${media.poster_path}`;
          this.movieRuntime = media.runtime
            ? media.runtime + " min"
            : "Seasons: " + media.number_of_seasons;
          this.movieDescription = media.overview;
          this.reviewsCount = media.vote_count;
          this.scrollToTop();
        } else {
          console.error("Media details not found");
        }
      } catch (error) {
        console.error("Failed to fetch media details:", error);
      }
    },
    async fetchTrailer() {
      const imdbId = localStorage.getItem("imdbId");
      if (!imdbId) return console.error("No IMDb ID found in localStorage");

      try {
        const findResponse = await axios.get(
          `https://api.themoviedb.org/3/find/${imdbId}?api_key=a6a07bdb1ae12672ae2d301063d83c40&language=en-US&external_source=imdb_id`
        );
        let mediaType = null;
        let mediaId = null;

        if (findResponse.data.movie_results[0]) {
          mediaType = "movie";
          mediaId = findResponse.data.movie_results[0].id;
        } else if (findResponse.data.tv_results[0]) {
          mediaType = "tv";
          mediaId = findResponse.data.tv_results[0].id;
        }

        if (!mediaId) return console.error("Media ID not found");

        const videosResponse = await axios.get(
          `https://api.themoviedb.org/3/${mediaType}/${mediaId}/videos?api_key=a6a07bdb1ae12672ae2d301063d83c40&language=en-US`
        );
        const trailer = videosResponse.data.results.find(
          (video) => video.type === "Trailer"
        );
        if (trailer) {
          this.trailerKey = trailer.key;
          this.showDialog = true;
        } else {
          console.error("No trailers found for the media");
        }
      } catch (error) {
        console.error("Failed to fetch trailer:", error);
      }
    },
    async fetchSeasonsAndEpisodes(imdbId) {
      try {
        const response = await axios.get(
          `https://api.themoviedb.org/3/find/${imdbId}?api_key=a6a07bdb1ae12672ae2d301063d83c40&language=en-US&external_source=imdb_id`
        );
        const tvShow = response.data.tv_results[0];
        if (tvShow) {
          const tvShowId = tvShow.id;
          const seasonsResponse = await axios.get(
            `https://api.themoviedb.org/3/tv/${tvShowId}?api_key=a6a07bdb1ae12672ae2d301063d83c40&language=en-US`
          );
          const tvShowData = {
            tvShow,
            seasons: seasonsResponse.data.seasons,
          };
          localStorage.setItem("tvShowData", JSON.stringify(tvShowData));
          this.seasons = tvShowData.seasons.map(
            (season) => season.season_number
          );
          this.scrollToTop();
        } else {
          console.error("TV show details not found");
        }
      } catch (error) {
        console.error("Failed to fetch TV show details:", error);
      }
    },
    playNextEpisode() {
      const currentEpisodeIndex = this.episodes.findIndex(
        episode => episode.episode_number === this.selectedEpisode
      );

      if (currentEpisodeIndex >= 0 && currentEpisodeIndex < this.episodes.length - 1) {
        const nextEpisode = this.episodes[currentEpisodeIndex + 1];
        this.selectedEpisode = nextEpisode.episode_number;
        this.updateEmbedUrl();
        this.showVideoDialog = true;
        this.updateSeasonEpisodeInContinueWatching();
      } else {
        console.warn("No more episodes available.");
      }
    },
    async playContent() {
      const tmdbId = localStorage.getItem("imdbId");
      const authId = localStorage.getItem("authId");

      if (!tmdbId) {
        console.error("No IMDb ID found in localStorage");
        return;
      }

      if (!authId) {
        console.warn(
          "No auth ID found in localStorage. Skipping 'add to continue watching'."
        );
        this.checkIfMovie(tmdbId);
        return;
      }

      const isAlreadyWatched = await this.isAlreadyWatched(authId, tmdbId);
      if (isAlreadyWatched) {
        console.warn("Episode and season already in watch history.");
        this.checkIfMovie(tmdbId);
        return;
      }

      await this.addToContinueWatching(authId, tmdbId);
      this.checkIfMovie(tmdbId);
    },
    async isAlreadyWatched(authId, imdbId) {
      try {
        const continueWatchingRef = collection(db, "continue_watching");
        const q = query(
          continueWatchingRef,
          where("authId", "==", authId),
          where("imdbId", "==", imdbId),
          where("season", "==", this.selectedSeason),
          where("episode", "==", this.selectedEpisode)
        );
        const querySnapshot = await getDocs(q);

        return !querySnapshot.empty;
      } catch (error) {
        console.error("Error checking watch history:", error);
        return false;
      }
    },
    async addToContinueWatching(authId, imdbId) {
      try {
        const response = await axios.get(
          `https://api.themoviedb.org/3/find/${imdbId}?api_key=a6a07bdb1ae12672ae2d301063d83c40&language=en-US&external_source=imdb_id`
        );
        const media =
          response.data.movie_results[0] || response.data.tv_results[0];

        if (!media) {
          console.error("Media details not found");
          return;
        }

        const mediaData = {
          authId: authId,
          imdbId: imdbId,
          title: media.title || media.name,
          rating: media.vote_average,
          imageUrl: `https://image.tmdb.org/t/p/original${media.poster_path}`,
          runtime: media.runtime
            ? `${media.runtime} min`
            : `Seasons: ${media.number_of_seasons}`,
          description: media.overview,
          lastWatched: new Date(),
          lastEdited: new Date(),
          season: this.selectedSeason,
          episode: this.selectedEpisode,
        };

        const continueWatchingRef = collection(db, "continue_watching");
        const q = query(
          continueWatchingRef,
          where("authId", "==", authId),
          where("imdbId", "==", imdbId)
        );
        const querySnapshot = await getDocs(q);

        if (querySnapshot.empty) {
          await addDoc(collection(db, "continue_watching"), mediaData);
          console.log("Added to continue watching");
        } else {
          querySnapshot.forEach(async (docSnapshot) => {
            const docRef = doc(db, "continue_watching", docSnapshot.id);
            await updateDoc(docRef, {
              season: this.selectedSeason,
              episode: this.selectedEpisode,
              lastWatched: new Date(),
              lastEdited: new Date(),
            });
            console.log("Updated continue watching with new season and episode");
          });
        }
      } catch (error) {
        console.error("Error adding to continue watching:", error);
      }
    },
    async checkIfMovie(tmdbId) {
      try {
        const response = await axios.get(
          `https://api.themoviedb.org/3/find/${tmdbId}?api_key=a6a07bdb1ae12672ae2d301063d83c40&language=en-US&external_source=imdb_id`
        );
        const isMovie = response.data.movie_results.length > 0;
        if (isMovie) {
          this.embedUrl = `https://vidsrc.xyz/embed/movie/${tmdbId}`;
          this.showVideoDialog = true;
        } else {
          this.playTvShowContent(tmdbId);
        }
      } catch (error) {
        console.error("Failed to check if IMDb ID is for a movie:", error);
      }
    },
    playTvShowContent(tmdbId) {
      const season = this.selectedSeason || 1;
      const episode = this.selectedEpisode || 1;

      this.selectedSeason = season;
      this.selectedEpisode = episode;

      localStorage.setItem("selectedSeason", season);
      localStorage.setItem("selectedEpisode", episode);

      this.embedUrl = `https://vidsrc.xyz/embed/tv/${tmdbId}/${season}/${episode}`;
      this.showVideoDialog = true;
    },
    updateEmbedUrl() {
      const tmdbId = localStorage.getItem("imdbId");
      if (!tmdbId || !this.selectedSeason || !this.selectedEpisode) {
        console.error(
          "IMDb ID, selected season, or selected episode not available."
        );
        return;
      }

      localStorage.setItem("selectedSeason", this.selectedSeason);
      localStorage.setItem("selectedEpisode", this.selectedEpisode);

      this.embedUrl = `https://vidsrc.xyz/embed/tv/${tmdbId}/${this.selectedSeason}/${this.selectedEpisode}`;
    },
    async updateSeasonEpisodeInContinueWatching() {
      const imdbId = localStorage.getItem("imdbId");

      if (!imdbId) {
        console.error("No IMDb ID found in localStorage");
        return;
      }

      const authId = localStorage.getItem("authId");

      if (!authId) {
        console.warn(
          "No authId found in localStorage. Skipping update to continue watching."
        );
        return;
      }

      try {
        const continueWatchingRef = collection(db, "continue_watching");
        const q = query(
          continueWatchingRef,
          where("authId", "==", authId),
          where("imdbId", "==", imdbId)
        );
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          querySnapshot.forEach(async (docSnapshot) => {
            const docRef = doc(db, "continue_watching", docSnapshot.id);
            await updateDoc(docRef, {
              season: this.selectedSeason,
              episode: this.selectedEpisode,
              lastWatched: new Date(),
              lastEdited: new Date(),
            });
            console.log("Updated continue watching with new season and episode");
          });
        }
      } catch (error) {
        console.error("Error updating continue watching:", error);
      }
    },
    async fetchContinueWatching(authId, imdbId) {
      try {
        const continueWatchingRef = collection(db, "continue_watching");
        const q = query(
          continueWatchingRef,
          where("authId", "==", authId),
          where("imdbId", "==", imdbId)
        );
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          querySnapshot.forEach((docSnapshot) => {
            const data = docSnapshot.data();
            this.selectedSeason = data.season || 1;
            this.selectedEpisode = data.episode || 1;
            this.updateEmbedUrl();
          });
        }
      } catch (error) {
        console.error("Failed to fetch continue watching data:", error);
      }
    },
    async fetchEpisodes() {
      const imdbId = localStorage.getItem("imdbId");
      if (!imdbId) return console.error("No IMDb ID found in localStorage");

      const tvShowData = JSON.parse(localStorage.getItem("tvShowData"));
      if (!tvShowData) return console.error("TV show data not found in localStorage");

      const selectedSeasonData = tvShowData.seasons.find(season => season.season_number === this.selectedSeason);
      if (!selectedSeasonData) return console.error("Season data not found");

      const seasonNumber = selectedSeasonData.season_number;
      const response = await axios.get(
        `https://api.themoviedb.org/3/tv/${tvShowData.tvShow.id}/season/${seasonNumber}?api_key=a6a07bdb1ae12672ae2d301063d83c40&language=en-US`
      );
      this.episodes = response.data.episodes;

      if (this.initialFetchDone && this.episodes.length === 0) {
        this.initialFetchDone = true;
        this.fetchEpisodes();
      }
      this.scrollToTop();
    },
    async addToWatchlist() {
      const authId = localStorage.getItem("authId");
      const imdbId = localStorage.getItem("imdbId");

      if (!authId) {
        console.error("No authId found in localStorage");
        return;
      }

      if (!imdbId) {
        console.error("No IMDb ID found in localStorage");
        return;
      }

      const movieData = {
        authId: authId,
        imdbId: imdbId,
        title: this.movieTitle,
        rating: this.movieRating,
        imageUrl: this.moviePosterUrl,
        runtime: this.movieRuntime,
        description: this.movieDescription,
      };

      try {
        await addDoc(collection(db, "watchlist"), movieData);
        this.successMessage = "Movie added to watchlist successfully!";
        this.showSuccessAlert = true;
        setTimeout(() => {
          this.showSuccessAlert = false;
        }, 3000);
        this.scrollToTop();
      } catch (error) {
        console.error("Error adding movie to watchlist:", error);
      }
    },
    selectEpisode(episode) {
      this.selectedEpisode = episode.episode_number;
      this.updateEmbedUrl();
      this.showVideoDialog = true;
      this.updateSeasonEpisodeInContinueWatching();
    },
    changePlayer() {
      const tmdbId = localStorage.getItem("imdbId");
      if (!tmdbId || !this.selectedSeason || !this.selectedEpisode) {
        console.error(
          "IMDb ID, selected season, or selected episode not available."
        );
        return;
      }

      localStorage.setItem("selectedSeason", this.selectedSeason);
      localStorage.setItem("selectedEpisode", this.selectedEpisode);

      const currentUrl = localStorage.getItem("currentUrl");
      let newUrl = '';
      if (currentUrl === 'vidsrc.xyz') {
        newUrl = 'vidsrc.me';
      } else {
        newUrl = 'vidsrc.xyz';
      }
      localStorage.setItem("currentUrl", newUrl);

      this.embedUrl = `https://${newUrl}/embed/tv/${tmdbId}/${this.selectedSeason}/${this.selectedEpisode}`;
      this.showVideoDialog = true;
    },
    changePlayerMovie() {
      const tmdbId = localStorage.getItem("imdbId");
      if (!tmdbId || !this.selectedSeason || !this.selectedEpisode) {
        console.error(
          "IMDb ID, selected season, or selected episode not available."
        );
        return;
      }

      localStorage.setItem("selectedSeason", this.selectedSeason);
      localStorage.setItem("selectedEpisode", this.selectedEpisode);

      const currentUrl = localStorage.getItem("currentUrl");
      let newUrl = '';
      if (currentUrl === 'vidsrc.xyz') {
        newUrl = 'vidsrc.me';
      } else {
        newUrl = 'vidsrc.xyz';
      }

      localStorage.setItem("currentUrl", newUrl);
      this.embedUrl = `https://${newUrl}/embed/movie/${tmdbId}`;

      this.showVideoDialog = true;
    },
    async fetchMagnetLink() {
      const imdbId = localStorage.getItem("imdbId");
      if (!imdbId) return console.error("No IMDb ID found in localStorage");

      try {
        const response = await axios.get(
          `https://yts.mx/api/v2/list_movies.json?query_term=${imdbId}`
        );
        if (response.data && response.data.data.movies && response.data.data.movies.length > 0) {
          const movieData = response.data.data.movies[0];
          const torrentInfo = movieData.torrents[0];
          this.constructMagnetLink(torrentInfo.hash, movieData.title);
        } else {
          this.errorMessage = "No torrents found for the movie.";
          this.showErrorAlert = true;
        }
      } catch (error) {
        console.error("Error fetching magnet link:", error);
      }
    },
    constructMagnetLink(torrentHash, movieTitle) {
      const encodedMovieTitle = encodeURIComponent(movieTitle);
      const trackers = [
        'udp://open.demonii.com:1337/announce',
        'udp://tracker.openbittorrent.com:80',
        'udp://tracker.coppersurfer.tk:6969',
        'udp://glotorrents.pw:6969/announce',
        'udp://tracker.opentrackr.org:1337/announce',
        'udp://torrent.gresille.org:80/announce',
        'udp://torrent.gresille.org:80/announce',
        'udp://p4p.arenabg.com:1337',
        'udp://tracker.leechers-paradise.org:6969',
      ];

      let magnetLink = `magnet:?xt=urn:btih:${torrentHash}&dn=${encodedMovieTitle}`;
      trackers.forEach(tracker => {
        magnetLink += `&tr=${encodeURIComponent(tracker)}`;
      });

      this.magnetLink = magnetLink;
      window.open(magnetLink, "_blank");
    },
    openDownloadDialog() {
      this.showDownloadDialog = true;
    }
  },
  watch: {
    showVideoDialog(value) {
      if (value && !this.seasons.length) {
        const imdbId = localStorage.getItem("imdbId");
        if (imdbId) {
          this.fetchSeasonsAndEpisodes(imdbId);
        }
      }
    },
    selectedSeason(newSeason) {

      this.updateEmbedUrl();
      this.fetchEpisodes();
    },
    selectedEpisode(newEpisode) {
      this.updateEmbedUrl();
    },
  },
};
</script>

<style scoped>
.dialog-content {
  overflow-y: auto;
  max-height: 80vh;
}

.scroll-container {
  overflow-y: auto;
  max-height: 650px;
}

.scroll-container::-webkit-scrollbar {
  display: none;
}

.scroll-container {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.movie-banner {
  width: 100%;
  height: 600px;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  padding: 20px;
  color: white;
  position: relative;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-thumb {
  background-color: #23ffe5;
  border-radius: 10px;
}

::-webkit-scrollbar-track {
  background-color: #2c2c2c;
  border-radius: 10px;
}

.episode-item {
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.episode-item:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.play-button-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 2em;
  display: none;
}

.episode-item:hover .play-button-overlay {
  display: block;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.5) 30%,
    rgba(0, 0, 0, 1) 90%,
    rgba(0, 0, 0, 1) 100%
  );
  display: flex;
  align-items: center;
}

.movie-info {
  padding: 20px;
  z-index: 1;
}

.movie-title {
  font-size: 3em;
  font-weight: 700;
  color: white;
  margin-bottom: 0.2em;
  animation: fadeIn 1s ease-out forwards;
}

.movie-rating {
  display: flex;
  align-items: center;
}

.reviews-count {
  margin-left: 10px;
}

.movie-runtime,
.movie-description {
  margin-top: 10px;
}

.v-btn {
  font-size: 1rem;
  height: 46px;
}

.episode-list {
  background-color: black;
  overflow-y: auto;
  height: 550px;
  width: 80%;
}

.episode-dropdown {
  margin-bottom: 20px;
}

.episode-item {
  display: flex;
  align-items: center;
  height: 130px;
  background-color: black;

  position: relative;
}

.episode-item:hover .play-button-overlay {
  opacity: 1;
}

.play-button-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: opacity 0.3s ease;
  color: white;
  font-size: 2.5rem;
}

.v-list-item-title {
  font-weight: bold;
}

.v-list-item-subtitle {
  color: rgba(255, 255, 255, 0.7);
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
   100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.animate-title {
  animation: fadeInUp 1s ease-in-out;
}

.animate-description {
  animation: fadeInUp 1.5s ease-in-out;
}

@media (max-width: 600px) {
  .movie-title {
    font-size: 1.5rem;
  }

  .movie-description {
    font-size: 0.875rem;
  }

  .v-btn {
    font-size: 0.75rem;
    height: 26px;
  }
}
</style>
