<template>
  <div
    :class="['navigation-drawer', { expanded: isExpanded }]"
    @mouseenter="expandDrawer"
    @mouseleave="collapseDrawer"
  >
    <ul class="nav-list my-10">
      <li v-for="(item, index) in items" :key="index" class="nav-item" :class="{ 'active-page': $route.path === item.to }">
        <router-link :to="item.to" class="nav-link">
          <span class="icon" :class="{ 'active-icon': $route.path === item.to }">
            <lord-icon
              :src="item.icon"
              :colors="getIconColors($route.path === item.to)"
              trigger="hover"

              style="width:40px;height:40px">
            </lord-icon>
          </span>
          <span class="title mx-5 mb-3" :class="{ 'active-title': $route.path === item.to }">{{ item.title }}</span>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isExpanded: false,
      items: [
        { title: 'Home', icon: 'https://cdn.lordicon.com/cnpvyndp.json', to: '/' },
        { title: 'TV', icon: 'https://cdn.lordicon.com/aklfruoc.json', to: '/tv' },
        { title: 'My List', icon: 'https://cdn.lordicon.com/prjooket.json', to: '/list' },
        { title: 'Search', icon: 'https://cdn.lordicon.com/kkvxgpti.json', to: '/search' },
        
      ],
    }
  },
  methods: {
    expandDrawer() {
      this.isExpanded = true;
    },
    collapseDrawer() {
      this.isExpanded = false;
    },
    getIconColors(isActive) {
      return isActive ? 'primary:#0cdfe5' : 'primary:#ffffff';
    }
  }
}
</script>

<style>
.navigation-drawer {
  width: 65px;
  transition: width 0.3s;
  background: linear-gradient(to right, rgba(0, 0, 1, 0.9), rgba(0, 0, 0, 0.5));
  backdrop-filter: blur(10px);
  color: white;
  height: 100vh;
  position: fixed;
  z-index: 999;
  top: 0;
  border-radius: 0px 20px 20px 0px;
  left: 0;
  overflow: hidden;
}

.navigation-drawer.expanded {
  width: 240px;
  border-radius: 0px 20px 20px 0px;
  box-shadow: 2px 0px 15px rgba(255, 255, 255, 0.1);
}

.nav-list {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 90%;
}

.nav-item {
  display: flex;
  align-items: center;
  padding: 15px;
  transition: background-color 0.3s;
}

.nav-link {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: white;
  width: 100%;
}

.icon {
  margin-right: 10px;
  margin-left: -25px;
  color: white;
  font-size: 35px;
}

.active-icon {
  color: #0cdfe5 !important;
}

.title {
  font-size: 8px; /* Smaller font size */
  font-weight: 300; /* Thinner font weight */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: white;
}

.active-title {
  color: #0cdfe5 !important;
}

.navigation-drawer .title {
  display: none;
}

.navigation-drawer.expanded .title {
  display: inline;
}

.active-page .nav-link {
  color: #0cdfe5;
}
</style>
