<template>
    <div class="pa-10">
      <!-- Search input -->
      <v-row>
        <v-col cols="9">
          <v-text-field
            v-model="searchQuery"
            :loading="isLoading"
            dark
            solo
            width="100%"
            height="70px"
            append-icon="mdi-magnify"
            @input="fetchMedia"
          >
            <template v-slot:label>
              <span style="font-size: 20px;">Type to search...</span>
            </template>
          </v-text-field>
        </v-col>
        <v-col cols="3" class="d-flex align-center">
          <v-switch
            v-model="selectedType"
            :label="selectedType === 'movie' ? 'Netflix Movies' : 'Netflix TV Shows'"
            false-value="movie"
            true-value="tv"
            color="#23ffe5"
            @change="fetchMedia"
          ></v-switch>
        </v-col>
      </v-row>
  
      <!-- Filter button -->
      <v-row>
        <v-col cols="12">
          <v-icon @click="showFilterDialog" class="mt-5 mb-12" color="#23ffe5" large>mdi-filter</v-icon>
        </v-col>
      </v-row>
      
      <!-- Filter dialog -->
      <v-dialog v-model="filterDialog" max-width="500px">
        <v-card class="custom-card">
          <v-img src="../assets/logo1.png" alt="Logo" height="200px" contain class="image my-5"></v-img>
          <v-card-text>
            <v-row justify="center">
              <span style="margin-top:-65px" class="headline white--text">Filter</span>
            </v-row>
            <v-row justify="center">
              <v-select
                dark
                solo
                dense
                v-model="selectedGenres"
                :items="genres"
                label="Genres"
                multiple
                class="custom-select"
              ></v-select>
            </v-row>
            <v-row justify="center">
              <v-select
                dark
                solo
                dense
                v-model="selectedSort"
                :items="sortOptions"
                label="Sort By"
                class="custom-select"
              ></v-select>
            </v-row>
            <v-row justify="center">
              <v-select
                dark
                dense
                solo
                v-model="selectedYear"
                :items="yearOptions"
                label="Year"
                class="custom-select"
              ></v-select>
            </v-row>
            <v-row justify="center">
              <v-rating
                dark
                v-model="selectedRating"
                label="Minimum Rating"
                color="#23ffe5"
                class="custom-rating"
              ></v-rating>
            </v-row>
            <v-row justify="center" class="mt-5">
              <v-btn color="#23ffe5" width="150px" class="custom-button my-5" @click="applyFilters">Apply</v-btn>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
  
      <!-- Media items display -->
      <v-row>
        <v-col v-for="(item, index) in results" :key="getUniqueKey(item, index)" cols="6" sm="6" md="2" v-if="item.poster_path">
          <v-hover v-slot:default="{ hover }">
            <v-card
              @click="redirectToViewInfo(item)"
              class="d-flex flex-column overlay-container fade-in-left"
              :class="{ 'hover-scale': hover }"
              style="background-color:transparent; cursor: pointer;"
            >
              <v-skeleton-loader v-if="isLoading" type="image" style="height:100%"></v-skeleton-loader>
              <v-img v-else :src="getImageUrl(item.poster_path)" height="100%" class="white--text align-end">
                <template v-if="hover">
                  <div class="overlay-content">
                    <v-icon large color="white">mdi-play-circle-outline</v-icon>
                    <div class="text-h5 mt-2">{{ item.title }}</div>
                    <div class="text-subtitle-1">{{ item.release_date | formatDate }}</div>
                    <div class="text-caption">{{ truncatedText(item.overview) }}</div>
                  </div>
                </template>
              </v-img>
            </v-card>
          </v-hover>
        </v-col>
      </v-row>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  import { VImg, VCard, VRating, VSkeletonLoader } from 'vuetify/lib';
  
  export default {
    components: {
      VImg,
      VCard,
      VRating,
      VSkeletonLoader
    },
    data() {
      return {
        searchQuery: '',
        results: [],
        isLoading: false,
        filterDialog: false,
        selectedGenres: [],
        selectedType: 'movie', // Default to movies
        selectedYear: null,
        selectedLanguage: null,
        selectedRating: 0,
        selectedSort: null,
        genres: [
          { text: 'Action', value: 28 },
          { text: 'Comedy', value: 35 },
          { text: 'Drama', value: 18 },
          { text: 'Horror', value: 27 },
          { text: 'Romance', value: 10749 },
          { text: 'Science Fiction', value: 878 },
          { text: 'Documentary', value: 99 },
          { text: 'Fantasy', value: 14 },
          { text: 'Mystery', value: 9648 },
          { text: 'Animation', value: 16 },
          { text: 'Adventure', value: 12 },
          { text: 'Thriller', value: 53 },
          { text: 'Crime', value: 80 },
          { text: 'Family', value: 10751 },
          { text: 'War', value: 10752 },
          { text: 'Romantic Comedy', value: 10749 }
        ],
        sortOptions: [
          { text: 'Popularity', value: 'popularity.desc' },
          { text: 'Release Date', value: 'release_date.desc' },
          { text: 'Rating', value: 'vote_average.desc' }
        ],
        yearOptions: this.generateYearOptions(1900, new Date().getFullYear())
      };
    },
    mounted() {
      this.fetchDefaultMedia();
    },
    methods: {
      showFilterDialog() {
        this.filterDialog = true;
      },
      generateYearOptions(startYear, endYear) {
        const years = [];
        for (let year = endYear; year >= startYear; year--) {
          years.push({ text: year.toString(), value: year });
        }
        return years;
      },
      async fetchMedia(query = '') {
        this.isLoading = true;
        const apiKey = 'a6a07bdb1ae12672ae2d301063d83c40';
        const netflixProviderId = 8; // Netflix provider ID
        try {
          const fetchPage = async (page) => {
            let url;
            if (query) {
              url = `https://api.themoviedb.org/3/search/${this.selectedType}?api_key=${apiKey}&query=${encodeURIComponent(query)}&page=${page}&with_watch_providers=${netflixProviderId}&watch_region=US`;
            } else {
              const type = this.selectedType || 'movie';
              const yearParam = this.selectedYear ? `&${type === 'movie' ? 'primary_release_year' : 'first_air_date_year'}=${this.selectedYear}` : '';
              const languageParam = this.selectedLanguage ? `&with_original_language=${this.selectedLanguage}` : '';
              const ratingParam = this.selectedRating ? `&vote_average.gte=${this.selectedRating * 2}` : '';
              const sortParam = this.selectedSort ? `&sort_by=${this.selectedSort}` : '';
              url = `https://api.themoviedb.org/3/discover/${type}?api_key=${apiKey}&with_watch_providers=${netflixProviderId}&watch_region=US&with_genres=${this.selectedGenres.join(',')}${yearParam}${languageParam}${ratingParam}${sortParam}&page=${page}`;
            }
            const response = await axios.get(url);
            return response.data.results;
          };
  
          const results = await Promise.all([fetchPage(1), fetchPage(2), fetchPage(3), fetchPage(4), fetchPage(5)]);
          this.results = results.flat().map(item => ({
            ...item,
            type: item.media_type || this.selectedType,
            title: item.title || item.name,
            poster_path: item.poster_path,
            backdrop_path: item.backdrop_path,
            release_date: item.release_date || item.first_air_date,
            rating: item.vote_average / 2
          }));
        } catch (error) {
          console.error("Error fetching media:", error);
        } finally {
          this.isLoading = false;
        }
      },
      async fetchDefaultMedia() {
        this.isLoading = true;
        const apiKey = 'a6a07bdb1ae12672ae2d301063d83c40';
        const netflixProviderId = 8; // Netflix provider ID
        try {
          const fetchPage = async (page) => {
            const url = `https://api.themoviedb.org/3/discover/${this.selectedType}?api_key=${apiKey}&with_watch_providers=${netflixProviderId}&watch_region=US&page=${page}`;
            const response = await axios.get(url);
            return response.data.results;
          };
  
          const results = await Promise.all([fetchPage(1), fetchPage(2), fetchPage(3), fetchPage(4), fetchPage(5)]);
          this.results = results.flat().map(item => ({
            ...item,
            type: this.selectedType,
            title: item.title || item.name,
            poster_path: item.poster_path,
            backdrop_path: item.backdrop_path,
            release_date: item.release_date || item.first_air_date,
            rating: item.vote_average / 2
          }));
        } catch (error) {
          console.error("Error fetching media:", error);
        } finally {
          this.isLoading = false;
        }
      },
      applyFilters() {
        this.filterDialog = false;
        this.fetchMedia(this.searchQuery);
      },
      getImageUrl(path) {
        return path ? `https://image.tmdb.org/t/p/w500${path}` : 'default_image.jpg';
      },
      async redirectToViewInfo(item) {
        this.isLoading = true;
        const apiKey = 'a6a07bdb1ae12672ae2d301063d83c40';
        try {
          const detailsUrl = `https://api.themoviedb.org/3/${item.type}/${item.id}?api_key=${apiKey}&append_to_response=external_ids`;
          const response = await axios.get(detailsUrl);
          let imdbId;
          if (item.type === 'movie') {
            imdbId = response.data.imdb_id;
          } else if (item.type === 'tv') {
            imdbId = response.data.external_ids ? response.data.external_ids.imdb_id : null;
          }
          if (imdbId) {
            localStorage.setItem('imdbId', imdbId);
            this.$router.push(`/viewInfo?id=${item.id}&type=${item.type}`);
          } else {
            console.error("IMDb ID not found for the item.");
          }
        } catch (error) {
          console.error("Error fetching item details:", error);
        } finally {
          this.isLoading = false;
        }
      },
      truncatedText(text, maxLength = 100) {
        return text.length > maxLength ? text.substring(0, maxLength) + '...' : text;
      },
      getUniqueKey(item, index) {
        return `${item.id}-${item.media_type || this.selectedType}-${index}`;
      }
    }
  };
  </script>
  
  <style scoped>
  .d-flex {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .overlay-container {
    position: relative;
    transition: transform 0.3s ease-in-out;
  }
  .overlay-container.hover-scale {
    transform: scale(1.06);
  }
  .overlay-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(10px);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
  }
  
  .text-h5, .text-subtitle-1, .text-caption {
    color: white;
    margin-top: 8px;
  }
  .custom-card {
    background-color: black;
  }
  
  .custom-select {
    width: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .custom-rating {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  /* Added fade-in-left animation */
  .fade-in-left {
    -webkit-animation: fade-in-left 0.6s cubic-bezier(0.39, 0.575, 0.565, 1.000) both;
    animation: fade-in-left 0.6s cubic-bezier(0.39, 0.575, 0.565, 1.000) both;
  }
  
  @-webkit-keyframes fade-in-left {
    0% {
      -webkit-transform: translateX(-50px);
      transform: translateX(-50px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
      opacity: 1;
    }
  }
  
  @keyframes fade-in-left {
    0% {
      -webkit-transform: translateX(-50px);
      transform: translateX(-50px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
      opacity: 1;
    }
  }
  </style>
  