<template>
  <v-carousel hide-delimiters show-arrows-on-hover cycle height="660px" class="cara">
    <v-carousel-item
      v-for="(movie, index) in movies"
      :key="index"
      :src="`https://image.tmdb.org/t/p/original${movie.backdrop_path}`"
      class="carousel-item"
      @mouseover="showTrailer(index)"
      @mouseleave="hideTrailer(index)"
    >
      <div class="overlay"></div>
      <div class="banner-content">
        <v-container fluid>
          <v-row>
            <v-col cols="12" sm="8" md="6">
              <transition name="fade" appear>
                <div class="movie-title">{{ movie.title }}</div>
              </transition>
              <transition name="slide-fade" appear>
                <div class="movie-info">
                  <span class="rating"><v-icon color="#23ffe5">mdi-star</v-icon> {{ movie.vote_average }}</span>
                  <span class="year">|  {{ movie.release_date.split('-')[0] }}</span>
                  <span class="duration">| {{ formatRuntime(movie.runtime) }}</span>
                  <span class="rating-badge ">{{ movie.certification }}</span>
                </div>
              </transition>
              <transition name="slide-fade" appear>
                <p class="description">{{ movie.overview }}</p>
              </transition>
              <v-btn height="46px" width="130px" color="#23ffe5" @click="viewInfo(movie.imdb_id)">Play</v-btn>
              <v-btn height="46px" width="130px" class="mx-3" outlined dark @click="viewInfo(movie.imdb_id)">
                More Info
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <div
        style="z-index:8;position: absolute; top: 0; left: 0; width: 100%; height: 100%; background: linear-gradient(to bottom, rgba(0,0,0,1) 0%, rgba(0,0,0,0.9) 10%, rgba(0,0,0,0.5) 30%, rgba(0,0,0,0) 50%, rgba(0,0,0,0.5) 70%, rgba(0,0,0,0.9) 90%, rgba(0,0,0,1) 100%);"
      ></div>

      <div v-if="movie.showTrailer" class="trailer">
        <iframe
          :src="`${movie.trailer_url}?autoplay=1&mute=1&controls=0&modestbranding=1&showinfo=0&rel=0&fs=0&iv_load_policy=3&disablekb=1&playsinline=1`"
          frameborder="0"
          style="position: absolute; top: 50%; left: 50%; width: 177.77777778vh; height: 56.25vw; min-width: 100%; min-height: 100%; transform: translate(-50%, -50%);"
          allow="autoplay; fullscreen"
          allowfullscreen
        ></iframe>
      </div>
    </v-carousel-item>
  </v-carousel>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      movies: [],
      apiKey: 'a6a07bdb1ae12672ae2d301063d83c40',
      baseUrl: 'https://api.themoviedb.org/3',
    };
  },

  methods: {
    async fetchMovies() {
      try {
        const response = await axios.get(
          `${this.baseUrl}/movie/now_playing?api_key=${this.apiKey}&language=en-US`
        );
        const genreMap = await this.fetchGenres();

        const moviesWithDetails = await Promise.all(
          response.data.results.map(async (movie) => {
            const movieDetails = await this.fetchMovieDetails(movie.id);
            return {
              ...movie,
              genres: movie.genre_ids.map((id) => genreMap[id]).filter(Boolean),
              imdb_id: movieDetails.imdb_id,
              trailer_url: movieDetails.trailer_url,
              runtime: movieDetails.runtime,
              vote_average: movieDetails.vote_average,
              certification: movieDetails.certification,
              showTrailer: false,
            };
          })
        );

        this.movies = moviesWithDetails;
      } catch (error) {
        console.error('Failed to fetch movies:', error);
      }
    },
    async fetchGenres() {
      const response = await axios.get(
        `${this.baseUrl}/genre/movie/list?api_key=${this.apiKey}&language=en-US`
      );
      const genres = response.data.genres;
      return genres.reduce((map, genre) => {
        map[genre.id] = genre.name;
        return map;
      }, {});
    },
    async fetchMovieDetails(movieId) {
      const response = await axios.get(
        `${this.baseUrl}/movie/${movieId}?api_key=${this.apiKey}&language=en-US&append_to_response=release_dates,videos`
      );
      const movieDetails = response.data;

      const certification = this.getCertification(movieDetails.release_dates);

      const trailer = movieDetails.videos.results.find(
        (video) => video.type === 'Trailer' && video.site === 'YouTube'
      );

      return {
        ...movieDetails,
        trailer_url: trailer ? `https://www.youtube.com/embed/${trailer.key}` : null,
        certification,
      };
    },
    getCertification(releaseDates) {
      const usRelease = releaseDates.results.find(
        (release) => release.iso_3166_1 === 'US'
      );
      if (usRelease && usRelease.release_dates.length > 0) {
        return usRelease.release_dates[0].certification || 'NR';
      }
      return 'NR'; // NR for Not Rated
    },
    formatRuntime(runtime) {
      const hours = Math.floor(runtime / 60);
      const minutes = runtime % 60;
      return `${hours} hr ${minutes} mins`;
    },
    viewInfo(imdbId) {
      localStorage.setItem('imdbId', imdbId);
      this.$router.push({ path: '/viewInfo', query: { id: imdbId } });
    },
    showTrailer(index) {
      this.$set(this.movies[index], 'showTrailer', true);
    },
    hideTrailer(index) {
      this.$set(this.movies[index], 'showTrailer', false);
    },
  },
  mounted() {
    this.fetchMovies();
  },
};
</script>

<style scoped>
.carousel-item {
  position: relative;
  color: white;
  text-shadow: 0 2px 10px rgba(0, 0, 0, 0.7);
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.banner-content {
  position: absolute;
  bottom: 20%;
  left: 10%;
  max-width: 80%;
  z-index: 9;
}

.movie-title {
  font-size: 3em;
  font-weight: 700;
  color: white;
  margin-bottom: 0.2em;
  animation: fadeIn 1s ease-out forwards;
}

.movie-info {
  font-size: 1.2em;
  color: white;
  margin-bottom: 0.5em;
  display: flex;
  align-items: center;
  animation: slideFadeIn 1.2s ease-out forwards;
}

.rating, .year, .duration, .rating-badge {
  margin-right: 1em;
}

.rating-badge {
  background-color: #23ffe5;
  padding: 0.2em 0.5em;
  border-radius: 4px;
  font-size: 0.9em;
  color: white;
}

.description {
  font-size: 1.1em;
  color: white;
  margin-bottom: 1.5em;
  animation: slideFadeIn 1.5s ease-out forwards;
}

.play-btn {
  background-color: #7b61ff;
  color: white;
  height: 46px;
  width: 150px;
  margin-right: 1em;
  animation: fadeIn 1.7s ease-out forwards;
}

.watch-later-btn {
  color: white;
  border: 1px solid #7b61ff;
  height: 46px;
  width: 150px;
  animation: fadeIn 2s ease-out forwards;
}

.trailer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.trailer iframe {
  width: 100%;
  height: 100%;
  border: none;
  pointer-events: none; /* Disable pointer events to avoid interaction */
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideFadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Mobile Styles */
@media (max-width: 600px) {
  .movie-title {
    font-size: 1.8em;
  }

  .movie-info {
    font-size: 0.9em;
  }

  .description {
    font-size: 0.9em;
  }

  .play-btn, .watch-later-btn {
    width: 120px;
    height: 40px;
  }

  .banner-content {
    bottom: 10%;
    left: 5%;
    max-width: 90%;
  }
}
</style>
